<template>
  <div class="oneupload">
    <el-upload
      class="avatar-uploader"
      action="/shop/fileservice/uploadFile"
      :show-file-list="true"
      :on-success="handleAvatarSuccess"
      :headers="token"
      :data="{ moduleType: moduleType }"
    >
      <img v-if="imgField" :src="imgField" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
import { getToken } from "@/util/auth";
export default {
  name: "oneUpload",
  props: {
    moduleType: {
      type: String,
      default: () => {
        return "06";
      },
    },
    imgField: {
      type: String,
      default: () => {
        return "img";
      },
    },
  },
  data() {
    return {
      imgUrl: "",
      token: {},
    };
  },
  created() {
    this.getImage()
  },
  mounted() {
    // 获取token值
    this.token = {
      "Blade-Auth": "bearer " + getToken(),
    };
  },
  methods: {
    //上传营业执照,获取头像路径
    handleAvatarSuccess(res) {
      console.log(res.data);
      this.imgUrl = res.data[0].filePath;
      this.$emit('imgUrl', this.imgUrl);
      // console.log('图片地址是' + this.imgField)
    },
    // 上传前判断
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === "image/jpeg";
    //   const jsPNG = file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG && !jsPNG) {
    //     this.$message.error("上传图片只能是 JPG/PNG 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传图片大小不能超过 2MB!");
    //   }
    //   return (isJPG || jsPNG) && isLt2M;
    // },
    getImage() {
      this.imgUrl = this.imgField
      console.log("img"+ this.imgUrl)
    },
    clearData() {
      this.imgUrl = "";
    }
  },
};
</script>
<style lang="scss" scoped>
.oneupload {
  .avatar-uploader {
    width: 110px;
    height: 110px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
  }
  .avatar {
    width: 110px;
    height: 110px;
    display: block;
  }
}
</style>
