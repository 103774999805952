<!--
 * @Author: Jerry
 * @Date: 2021-01-31 10:27:05
 * @LastEditTime: 2021-03-15 17:06:19
-->
<template>
  <div>
    <el-table
      :header-cell-style="{ background: '#f4f4f4' }"
      :data="formData"
      style="width: 100%"
    >
      <el-table-column prop="changeTime" label="充值时间" width="200">
        <template slot-scope="scope">
          {{ formatDate(scope.row.changeTime * 1000) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="money" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="auditStatus" label="审核状态">
        <template slot-scope="scope">
          <span v-if="scope.row.auditStatus == 'D'" style="color: red">
            待审核
          </span>
          <span v-else-if="scope.row.auditStatus == 'N'" style="color: blue"
            >审核不通过</span
          >
          <span v-else-if="scope.row.auditStatus == 'Y'" style="color: green"
            >审核通过</span
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="proof" label="凭证">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.proof"
            :preview-src-list="[scope.row.proof]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <div
            style="display: flex; justify-content: center"
            v-if="scope.row.auditStatus == 'D'"
          >
            <!-- <div class="wid50 bg_666 het5"></div> -->
          </div>
          <div
            style="display: flex; justify-content: center"
            v-else-if="scope.row.auditStatus == 'Y'"
          >
            <!-- <div class="wid50 bg_666 het5"></div> -->
          </div>
          <div v-else-if="scope.row.auditStatus == 'N'">
            <div class="btn">
              <el-button type="primary" size="mini" plain @click="seeReason(scope.row.auditReason)">查看原因</el-button>
            </div>
            <div class="btn Mtp5">
              <el-button type="primary" size="mini" @click="resubmit(scope.row.id)">重新提交</el-button>
            </div>
            <div class="btn">
              <el-button type="primary" size="mini" @click="onClickEdit(scope.row)">修改</el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看原因 -->
    <el-dialog
      title="查看原因"
      :visible.sync="dialogReason"
      width="30%"
      :before-close="handleClose"
    >
      <span> {{auditReason}} </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeReason()">取 消</el-button>
        <el-button type="primary" @click="dialogReason = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <EditDialog v-if="editVisible" :visible="editVisible" :initialValue="initialValue" :onClickClose="onClickClose"/>
  </div>
</template>
<script>
import { formatDate } from "@/util/util";
import { againSubmitAuditData } from "@/api/product";
import EditDialog from './editDialog'
export default {
  components: {EditDialog},
  props: {
    formData: {
      type: Array,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      dialogReason: false,
      auditReason: "",
      editVisible: false,
      initialValue: {}
    };
  },
  // created() {
  //   console.log(this.formData)
  // },
  methods: {
    formatDate: formatDate,
    //查看原因
    seeReason(auditReason) {
      this.auditReason = auditReason
      console.log(auditReason)
      this.dialogReason = true;
    },
    //重新提交
    resubmit(id) {
      console.log(id)
      let data = {
        dataId: id
      }
      againSubmitAuditData(data).then((res) => {
        if (res.success) {
          this.$message.success(res.msg);
          this.$emit("refresh")
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closeReason() {
      this.dialogReason = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    onClickEdit(values){
      console.log('values', values)
      this.initialValue = values
      this.editVisible = true
    },
    onClickClose(){
      this.editVisible = false
      this.initialValue = {}
    }
  },
};
</script>
<style lang="scss" scoped>
.el-button {
  padding: 3px 10px !important;
}
.el-image {
  height: 60px;
  width: 50px;
}
</style>
