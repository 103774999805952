<!--
 * @Author: Jerry
 * @Date: 2021-02-02 09:24:43
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-15 17:04:29
 * @Description: file content
-->
<template>
  <div>
    <pwd-contair>
      <div slot="menu">账户变动-充值记录</div>
      <div slot="pwdContair" style="min-height: 400px">
        <div>
          <recharge-form :formData="dataSource" @refresh="loadData()"></recharge-form>
        </div>
        <div class="floatR Mtp20">
          <el-pagination
            background
            :total="ipagination.totalCount"
            :page-size="ipagination.size"
            :current-page="ipagination.current"
            @size-change="onSizeChange"
            @current-change="onPageChange"
            layout="total, sizes, prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import RechargeForm from "@/components/supplier/rechargeForm/rechargeForm.vue";
import { findUserTopUpOrConsumeList } from "@/api/user";
import { listMixin } from "@/mixins/listMixins";
import { mapGetters } from "vuex";
export default {
  mixins: [listMixin],
  components: { pwdContair, RechargeForm },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      extraParam: {
        userId: '',
        type: 1
      },
      url: {
        list: findUserTopUpOrConsumeList
      }
    };
  },
  mounted() {
    this.extraParam.type = 1
    this.extraParam.userId = this.userInfo.id;
    this.loadData()
  },
};
</script>
<style scoped lang="scss"></style>
