<!--
 * @Author: Jerry
 * @Date: 2021-01-31 10:27:05
 * @LastEditTime: 2021-03-15 17:06:19
-->
<template>
  <el-dialog
    title="查看原因"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <el-form
      :model="withdrawalForm"
      label-width="100px"
      :rules="withdrawalFormRule"
    >
      <el-row :gutter="1">
        <el-col :span="8">
          <el-form-item label="充值金额" prop="money">
            <el-input-number
              v-model="withdrawalForm.money"
              @change="handleChange"
              :min="1"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button
              :loading="loading"
              class="wid100"
              type="primary"
              @click="rechargeBtn()"
            >
              立即充值
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="上传凭证" prop="transferImage">
            <div>
              <one-upload
                ref="OneUpload"
                moduleType="04"
                :imgField="transferImage"
                @imgUrl="getImgUrl"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { createUserTopUpRecord } from "@/api/user";
import OneUpload from "@/components/upload/oneUpload.vue";
import {mapGetters} from "vuex";
export default {
  components: { OneUpload },
  props: {
    initialValue: {
      type: Object,
      default: function () {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    onClickClose: {
      type: Function,
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      loading: false,
      auditReason: "",
      withdrawalForm: {
        money: this.initialValue.money,
      },
      withdrawalType: "3",
      transferImage: this.initialValue.proof,
      withdrawalFormRule: {
        money: [{ required: true, message: "请输入充值金额", trigger: "blur" }],
        transferImage: [
          { required: true, message: "请上传转账凭证", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.GetLoginUserInfoByToken();
  },
  methods: {
    GetLoginUserInfoByToken() {
      this.$store.dispatch("GetLoginUserInfoByToken");
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.onClickClose()
          // done();
        })
        .catch((_) => {})
    },

    handleChange(val) {
      this.withdrawalForm.money = val;
    },
    getImgUrl(val) {
      this.transferImage = val;
    },
    // 立即充值
    rechargeBtn() {
      this.loading = true;
      createUserTopUpRecord(this.userInfo.id,
              this.withdrawalForm.money,
              this.transferImage,
      this.initialValue.id)
              .then((res) => {
                this.loading = false;
                if (res.success) {
                  this.GetLoginUserInfoByToken();
                  this.clearData();
                  this.$message.success(res.msg);
                  this.onClickClose()
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch(() => {
                this.loading = false;
              });
    },
    // 清空数据
    clearData() {
      this.withdrawalForm.money = 100;
      this.transferImage = "";
      this.$refs.OneUpload.clearData()
    },
    changePwd() {
      this.$router.push("/supplier/supplierHome/depositPwdChange");
    },
  }
};
</script>
